import { Link } from 'react-router-dom';
import ExternalLinkIcon from '../icon/ExternalLink';
import { DefaultOptionRenderer, Item } from './DropdownDefaultComponents';

export function OpenInNewTabValueRendererWrapper(newTabLinkProvider: (item: Item) => string) {
  return function OpenInNewTabValueRenderer(props: Item) {
    return (
      <div className="group flex items-center justify-between gap-2">
        <div className="truncate">
          <DefaultOptionRenderer {...props} />
        </div>
        {props.id && (
          <Link
            to={newTabLinkProvider(props)}
            target="_blank"
            className="hover:text-accent-1 pointer-events-auto flex-shrink-0 px-2 opacity-0 group-hover:opacity-100"
            onClick={(e) => e.stopPropagation()}
          >
            <ExternalLinkIcon className="h-6 w-6" />
          </Link>
        )}
      </div>
    );
  };
}
